// Generated by Framer (83eb5d8)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, getFonts, RichText, useActiveVariantCallback, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";
import Caret from "https://framerusercontent.com/modules/ILZXZ5AuNB8p8klAKXC6/fHXbNUB2sw9dvuqLNbAT/YXS5TuLOj.js";
import { OpenType } from "https://framerusercontent.com/modules/wtCWCDefZAF8uwRUa8z9/K7SB4rOAiUHTg03TJ5iQ/Overrides.js";
const RichTextOpenType = OpenType(RichText);
const CaretFonts = getFonts(Caret);

const cycleOrder = ["HSlLV7fgW", "SgkHABZmX"];

const serializationHash = "framer-jjiy9"

const variantClassNames = {HSlLV7fgW: "framer-v-1dsrod6", SgkHABZmX: "framer-v-hvv2lg"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transitions = {default: {damping: 40, delay: 0, mass: 1, stiffness: 600, type: "spring"}};

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const humanReadableVariantMap = {Closed: "HSlLV7fgW", Open: "SgkHABZmX"}

const getProps = ({height, hover, id, title, width, ...props}) => { return {...props, DApvjrdfG: title ?? props.DApvjrdfG ?? "Product", PWSlWeqS6: hover ?? props.PWSlWeqS6, variant: humanReadableVariantMap[props.variant] ?? props.variant ?? "HSlLV7fgW"} }

const createLayoutDependency = (props, variants) => variants.join('-') + props.layoutDependency

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;variant?: keyof typeof humanReadableVariantMap;hover?: any;title?: string; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, PWSlWeqS6, DApvjrdfG, ...restProps} = getProps(props)

const {baseVariant, classNames, gestureVariant, setGestureState, setVariant, transition, variants} = useVariantState({cycleOrder, defaultVariant: "HSlLV7fgW", transitions, variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const { activeVariantCallback, delay } = useActiveVariantCallback(baseVariant)

const onMouseEnter1nzhzpa = activeVariantCallback(async (...args) => {
if (PWSlWeqS6) {
const res = await PWSlWeqS6(...args);
if (res === false) return false;
}
})

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = []

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<Transition value={transition}><motion.div {...restProps} animate={variants} className={cx(serializationHash, ...sharedStyleClassNames, "framer-1dsrod6", className, classNames)} data-framer-name={"Closed"} data-highlight initial={variant} layoutDependency={layoutDependency} layoutId={"HSlLV7fgW"} onHoverEnd={() => setGestureState({isHovered: false})} onHoverStart={() => setGestureState({isHovered: true})} onMouseEnter={onMouseEnter1nzhzpa} onTap={() => setGestureState({isPressed: false})} onTapCancel={() => setGestureState({isPressed: false})} onTapStart={() => setGestureState({isPressed: true})} ref={ref ?? ref1} style={{...style}} {...addPropertyOverrides({SgkHABZmX: {"data-framer-name": "Open"}}, baseVariant, gestureVariant)}><RichTextOpenType __fromCanvasComponent children={<React.Fragment><motion.p style={{"--font-selector": "R0Y7QXJjaGl2by01MDA=", "--framer-font-family": "\"Archivo\", \"Archivo Placeholder\", sans-serif", "--framer-font-size": "15px", "--framer-font-weight": "500", "--framer-letter-spacing": "-0.01em", "--framer-line-height": "2em", "--framer-text-alignment": "left", "--framer-text-color": "var(--extracted-r6o4lv, rgb(255, 255, 255))"}}>Product</motion.p></React.Fragment>} className={"framer-1uglcud"} fonts={["GF;Archivo-500"]} layoutDependency={layoutDependency} layoutId={"auMY0GWBl"} style={{"--extracted-r6o4lv": "rgb(255, 255, 255)", opacity: 1}} text={DApvjrdfG} variants={{SgkHABZmX: {opacity: 0.5}}} verticalAlignment={"top"} withExternalLayout/><motion.div className={"framer-1kcgigm-container"} layoutDependency={layoutDependency} layoutId={"d1_cz_GiQ-container"}><Caret height={"100%"} id={"d1_cz_GiQ"} layoutId={"d1_cz_GiQ"} variant={"xzGBfrweG"} width={"100%"} {...addPropertyOverrides({SgkHABZmX: {variant: "dsZwaWdcl"}}, baseVariant, gestureVariant)}/></motion.div></motion.div></Transition>
</LayoutGroup>)

});

const css = [".framer-jjiy9[data-border=\"true\"]::after, .framer-jjiy9 [data-border=\"true\"]::after { content: \"\"; border-width: var(--border-top-width, 0) var(--border-right-width, 0) var(--border-bottom-width, 0) var(--border-left-width, 0); border-color: var(--border-color, none); border-style: var(--border-style, none); width: 100%; height: 100%; position: absolute; box-sizing: border-box; left: 0; top: 0; border-radius: inherit; pointer-events: none; }", "@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-jjiy9.framer-1oirz4p, .framer-jjiy9 .framer-1oirz4p { display: block; }", ".framer-jjiy9.framer-1dsrod6 { align-content: center; align-items: center; display: flex; flex-direction: row; flex-wrap: nowrap; gap: 5px; height: min-content; justify-content: center; overflow: hidden; padding: 5px 0px 5px 0px; position: relative; width: min-content; }", ".framer-jjiy9 .framer-1uglcud { -webkit-user-select: none; flex: none; height: auto; pointer-events: none; position: relative; user-select: none; white-space: pre; width: auto; }", ".framer-jjiy9 .framer-1kcgigm-container { flex: none; height: auto; position: relative; width: auto; }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-jjiy9.framer-1dsrod6 { gap: 0px; } .framer-jjiy9.framer-1dsrod6 > * { margin: 0px; margin-left: calc(5px / 2); margin-right: calc(5px / 2); } .framer-jjiy9.framer-1dsrod6 > :first-child { margin-left: 0px; } .framer-jjiy9.framer-1dsrod6 > :last-child { margin-right: 0px; } }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 40
 * @framerIntrinsicWidth 72
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["auto","auto"]},"SgkHABZmX":{"layout":["auto","auto"]}}}
 * @framerVariables {"PWSlWeqS6":"hover","DApvjrdfG":"title"}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 */
const FramerqWgKjgpD_: React.ComponentType<Props> = withCSS(Component, css, "framer-jjiy9") as typeof Component;
export default FramerqWgKjgpD_;

FramerqWgKjgpD_.displayName = "Trigger";

FramerqWgKjgpD_.defaultProps = {height: 40, width: 72};

addPropertyControls(FramerqWgKjgpD_, {variant: {options: ["HSlLV7fgW", "SgkHABZmX"], optionTitles: ["Closed", "Open"], title: "Variant", type: ControlType.Enum}, PWSlWeqS6: {title: "Hover", type: ControlType.EventHandler}, DApvjrdfG: {defaultValue: "Product", displayTextArea: false, title: "Title", type: ControlType.String}} as any)

addFonts(FramerqWgKjgpD_, [{family: "Archivo", source: "google", style: "normal", url: "https://fonts.gstatic.com/s/archivo/v19/k3k6o8UDI-1M0wlSV9XAw6lQkqWY8Q82sJaRE-NWIDdgffTTBjNp9R1oJ0vyVQ.woff2", weight: "500"}, ...CaretFonts])